import React from "react";
import { Blockquote, Avatar } from "flowbite-react";

import test from '../../assets/test.png'
import company1 from '../../assets/company/IMG_5322.JPG'
import boss from '../../assets/company/IMG_5320.jpg'
import company2 from '../../assets/company/IMG_5317.JPG'
import paul from '../../assets/company/Paul.jpg'
import edison from '../../assets/company/edison.jpg'
import tina from '../../assets/company/tina.jpg'

const AboutUs = () =>{
    return(
        <div className="" id="about">
        {/* title */}
        <div className="w-full flex flex-col items-center p-10">
            <div className="h-1 w-16 bg-gradient-to-r from-[#F7936F] to-[#FFEF5E]"></div>
            <h1 className="mt-6 font-semibold text-[#333333] text-2xl">About Us</h1>
        </div>

        {/* content + photo */}
        <div className="grid col-span-1 md:col-span-2 gap-10 p-12 text-[#333333]">
            {/* Section 1: our team */}
            <div className="col-start-1">
                {/* title + text */}
                <h2 className="font-semibold mb-3">Our Team</h2>
                <p className="mb-3 text-xs">All of our team members hold at least a Master's degree or PhD in their areas of expertise, which include software development, computer vision, and AI / machine learning.  </p>
                <p className="text-xs">Our team is more than just a group of technical experts – we are a passionate and forward-thinking collective that believes in the power of IT to reshape industries and create lasting impact. We are developing intelligent systems that improve business efficiency, creating innovative solutions that help businesses to grow. </p>
                <Blockquote className="my-4 border-l-4 border-[#F7936F] p-4 text-[#F16063] dark:text-[#F16063]">
                    "At QX Tech, we’re not just here to build IT solutions—we’re here to help you grow."</Blockquote>
                <figcaption className="mt-6 flex items-center justify-start space-x-3">
                    {/* 拍了profile photo 把老板照片放这里 */}
                    <Avatar rounded size="xs" img={edison} alt="boss" /> 
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <cite className="pr-3 font-xs">Edison Wu</cite>
                        <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Co-founder & CEO at QX Tech</cite>
                    </div>
                </figcaption> 
            </div>
            {/* photo */}
            <div className="md:col-start-2">
                {/* 放一张办公室照片 */}
                <img src={company1}
                className="w-full h-full"/>
            </div>

            {/* Section 2: we believe in */}
            <div className="row-start-4 md:row-start-2 md:col-start-1">
                {/* 放第二张办公室照片 */}
                <img src={boss}
                className="w-full h-full"/>
            </div>
            <div className="row-start-3 md:row-start-2 md:col-start-2">
                <h2 className="font-semibold mb-3">We believe in</h2>
                <p className="mb-3 text-xs">At QX Tech, we believe in building relationships founded on trust, collaboration, and shared success. From the first consultation to post-launch support, we work closely with you, aligning our strategies with your business goals to maximise value and ensure every investment counts. </p>
                <Blockquote className="my-4 border-l-4 border-[#F7936F] p-4 text-[#F16063] dark:text-[#F16063]">
                    "The future is unwritten, yet it is our vision and innovation that will make it extraordinary."</Blockquote>
                <figcaption className="mt-6 flex items-center justify-start space-x-3">
                    {/* 拍了profile photo 把老板照片放这里 */}
                    <Avatar rounded size="xs" img={paul} alt="boss" /> 
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <cite className="pr-3 font-xs">Paul Liu</cite>
                        <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Co-founder & CTO at QX Tech</cite>
                    </div>
                </figcaption> 
            </div>

            {/* Section 3: our value */}
            <div className="row-start-5 md:row-start-3 md:col-start-1">
                <h2 className="font-semibold mb-3">Our Value</h2>
                <p className="mb-3 text-xs">We believe in innovation, collaboration, and excellence. We are dedicated to making your vision a reality and empowering your business to thrive in today’s digital world.</p>
                <p className="mb-3 text-xs">Our focus on quality, transparency, and long-term partnerships ensures that our clients achieve their goals and realise lasting value.</p>
                <Blockquote className="my-4 border-l-4 border-[#F7936F] p-4 text-[#F16063] dark:text-[#F16063]">
                    "We help your business cross the bridge to a smarter, more connected future."</Blockquote>
                <figcaption className="mt-6 flex items-center justify-start space-x-3">
                    {/* 拍了profile photo 把老板照片放这里 */}
                    <Avatar rounded size="xs" img={tina} alt="boss" /> 
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <cite className="pr-3 font-xs">Tina Ma</cite>
                        <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Project Manager at QX Tech</cite>
                    </div>
                </figcaption> 
            </div>
            <div className="row-start-6 md:row-start-3 md:col-start-2">
                <img src={company2} />
            </div>

        </div> 
        </div>
    )
}
export default AboutUs