import React from 'react';
import Header from './components/Common/Header.jsx'
import Footer from './components/Common/Footer.jsx'
import Hero from './components/Pages/Hero.jsx';
import Servicecard from './components/services/servicecard.jsx';
import LogoBanner from './components/ourpartnerslogos/LogoBanner.jsx';
import Cases from './components/cases/Cases.jsx';
import AboutUs from './components/aboutus/Aboutus.jsx';
import OurTech from './components/aboutus/OurTech.jsx';
import WorkWithUs from './components/aboutus/WorkWithUs.jsx';

const App = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <main className="relative">
        <div className="flex-grow">
          <Hero />      
        </div>
        <Servicecard />
        <LogoBanner />
        <Cases />
        <AboutUs />
        <OurTech />
        <WorkWithUs />
        <Footer />
      </main>
    </div>
  );
}

export default App;