import React, { useState, useEffect } from 'react';
import { Navbar } from "flowbite-react";
import logo from '../../assets/logo.png';

const Header = () => {
   const [isMenuOpen, setIsMenuOpen] = useState(false);

   useEffect(() => {
       // 关闭菜单当屏幕尺寸变化时
       const handleResize = () => {
           if (window.innerWidth >= 768) { // md breakpoint
               setIsMenuOpen(false);
           }
       };

       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
   }, []);

   return (
       <div className="w-screen">
           <Navbar fluid 
               className="fixed w-full top-0 bg-white border-gray-200 z-10"
           >
               {/* Logo */}
               <Navbar.Brand href="#" className="flex items-center pl-4 md:pl-12 py-2">
                   <img src={logo} className="h-8 mr-3" alt="qixin logo" />
                   <span className="self-center text-2xl font-semibold whitespace-nowrap">
                       QX Tech
                   </span>
               </Navbar.Brand>

               {/* 导航菜单按钮 */}
               <div className="flex md:hidden items-center pr-4 md:pr-12">
                   <button
                       onClick={() => setIsMenuOpen(!isMenuOpen)}
                       className="p-2 focus:outline-none"
                   >
                       <span className="sr-only">Open main menu</span>
                       <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                           <path 
                               strokeLinecap="round" 
                               strokeLinejoin="round" 
                               strokeWidth="2" 
                               d={isMenuOpen 
                                   ? "M6 18L18 6M6 6l12 12" 
                                   : "M4 6h16M4 12h16M4 18h16"
                               }
                           />
                       </svg>
                   </button>
               </div>

               {/* 导航链接 */}
               <div className={`${
                   isMenuOpen ? 'block' : 'hidden'
               } w-full md:block md:w-auto md:ml-auto pr-4 md:pr-12`}>
                   <nav className="flex flex-col md:flex-row md:items-center [&>*]:list-none [&>*]:marker:content-none">
                       <Navbar.Link 
                           href='#services' 
                           className='px-4 py-2 text-gray-700 hover:text-[#F7936F] text-lg font-semibold list-none'
                           onClick={() => setIsMenuOpen(false)}
                       >
                           Services
                       </Navbar.Link>
                       <Navbar.Link 
                           href='#cases' 
                           className='px-4 py-2 text-gray-700 hover:text-[#F7936F] text-lg font-semibold list-none'
                           onClick={() => setIsMenuOpen(false)}
                       >
                           Cases
                       </Navbar.Link>
                       <Navbar.Link 
                           href='#workwithus' 
                           className='px-4 py-2 text-gray-700 hover:text-[#F7936F] text-lg font-semibold list-none'
                           onClick={() => setIsMenuOpen(false)}
                       >
                           Collaboration
                       </Navbar.Link>
                       <Navbar.Link 
                           href='#about' 
                           className='px-4 py-2 text-gray-700 hover:text-[#F7936F] text-lg font-semibold list-none'
                           onClick={() => setIsMenuOpen(false)}
                       >
                           About us
                       </Navbar.Link>
                   </nav>
               </div>
           </Navbar>
       </div>
   );
}

export default Header;