import React, {useState} from "react";


const OurTech = () =>{
    const [activeTab, setActiveTab] = useState(0);
    const tabTitles = ["Frontend", "Backend", "Database & Cloud", "Data Analysis", "Game"];

    const tabContent = [
        <div key="frontend" className="flex flex-wrap gap-20 justify-center px-6 pb-12">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" 
            alt="React Logo"
            className="w-[48px] h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg"
            alt="Vue Logo" 
            className="w-[48px] h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/e/ea/Android_logo_2023_%28stacked%29.svg"
            alt="Android Logo"
            className="w-fit h-[48px]" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/9d/Swift_logo.svg"
            alt="Swift Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Google-flutter-logo.svg"
            alt="Flutter"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/92/A-Frame_logo.png" 
            alt="A-Frame Logo"
            className="w-fit h-[48px]"/>
        </div>,

        <div key="backend" className="flex flex-wrap gap-20 justify-center px-6 pb-12">
            <img src="https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg"
            alt="Java Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg"
            alt="Python Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png"
            alt="Javascript Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg"
            alt="PHP Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg"
            alt="Nodejs Logo"
            className="w-fit h-[48px]"/>
        </div>,

        <div key="database" className="flex flex-wrap gap-20 justify-center px-6 pb-12">
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"
            alt="AWS Cloud Services"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg"
            alt="Mongo DB Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/en/d/dd/MySQL_logo.svg"
            alt="MySql Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg"
            alt="PSQL logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/37/Firebase_Logo.svg"
            alt="Firebase Logo"
            className="w-fit h-[48px]"/>
        </div>,
        <div key="analysis" className="flex flex-wrap gap-20 justify-center px-6 pb-12">
            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg"
            alt="Python Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/TensorFlow_logo.svg"
            alt="tensorflow Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c6/PyTorch_logo_black.svg"
            alt="Pytouch Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/05/Scikit_learn_logo_small.svg"
            alt="Scikit Learn Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/1/1b/R_logo.svg"
            alt="R logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/4/4b/Tableau_Logo.png"
            alt="Tableau Logo"
            className="w-fit h-[48px]"/>
        </div>,
        
        <div key="game" className="flex flex-wrap gap-20 justify-center px-6 pb-12">
            <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/Unity_Technologies_logo.svg"
            alt="Unity Logo"
            className="w-fit h-[48px]"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/0c/Blender_logo_no_text.svg"
            alt="Blender"
            className="w-fit h-[48px]"/>
        </div>
    ]

    return(
        <div className="bg-white">
            {/* title */}
            <div className="w-full flex flex-col items-center p-10">
                <div className="h-1 w-16 bg-gradient-to-r from-[#F7936F] to-[#FFEF5E]"></div>
                <h1 className="mt-6 font-semibold text-[#333333] text-2xl">Our Tech Stack</h1>
            </div>

            {/* tabs */}
            {/* Tab Headers */}
            <div className="flex flex-wrap justify-center space-x-4 pb-2">
                {tabTitles.map((title, index) => (
                <button
                key={index}
                onClick={() => setActiveTab(index)} // Update active tab on click
                className={`text-base font-medium py-2 px-4 ${activeTab === index ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500 hover:text-blue-500"}`}>
                    {title}</button>
                ))}
            </div>
            
            {/* Tab Content */}
            <div className="mt-12 mx-4">
                {tabContent[activeTab]}
            </div>

        </div>
    )
}
export default OurTech